import styled from 'styled-components';

import { CookieConsentUsercentrics } from '@xing-com/cookie-consent-usercentrics';
import type { RouteMeta } from '@xing-com/crate-communication-data-layer';
import { useLoginState } from '@xing-com/crate-hooks-use-login-state';
import {
  useHost,
  useLoginAppUrl,
  useMetadata,
  useXinglet,
  XingletLoader,
} from '@xing-com/crate-xinglet';
import { ActiveItemContext, useViewerData } from '@xing-com/hub';
import { SkeletonRectangle } from '@xing-com/skeleton';
import { cornerRadiusL, spaceXL } from '@xing-com/tokens';

import { AdobePageViewTracker } from './adobe-page-view-tracker/adobe-page-view-tracker';
import { NoAccessPage, Generic } from './error-components';
import { LayoutSwitcher } from './layout-switcher';
import { Zerofier } from './zerofier';

declare global {
  // eslint-disable-next-line no-var
  var DD_RUM: // eslint-disable-next-line @typescript-eslint/no-explicit-any
  { setGlobalContextProperty: (key: string, value: any) => void } | undefined;
}

export type RouteProps = {
  path?: string;
  data: RouteMeta;
};

const StyledSkeletonRectangle = styled(SkeletonRectangle)`
  max-width: 596px;
  margin: ${spaceXL} auto;
`;

export const RouteXinglet: React.FC<{
  name: string;
  pageName?: string;
  trackingChannel?: string;
}> = ({ name, pageName, trackingChannel }) => {
  const { ssr } = useMetadata(name);
  const { loading: loadingXinglet, error } = useXinglet(name);
  const { loading: loadingViewerData } = useViewerData();

  if (error) {
    return <Generic />;
  }

  if (loadingXinglet || (!ssr && loadingViewerData)) {
    const skeletonHeights = ['200px', '200px', '124px', '324px', '124px'];
    return (
      <>
        {skeletonHeights.map((height, index) => (
          <StyledSkeletonRectangle
            key={index}
            height={height}
            cornerRadius={cornerRadiusL}
          />
        ))}
      </>
    );
  }

  return (
    <>
      <AdobePageViewTracker
        pageName={pageName}
        trackingChannel={trackingChannel}
      />
      <Zerofier />
      <XingletLoader name={name} key={name} error={<Generic />} />
    </>
  );
};

export const Route: React.VFC<RouteProps> = ({ data }) => {
  const {
    layout: layoutLoggedOut,
    layoutLoggedIn,
    activeItem,
    visibleTo,
    xinglet: xingletName,
    pageName,
    trackingChannel,
    loginDestination,
    loginTracking,
    disableCookieConsentBanner,
  } = data;

  // Set xinglet as global context for Datadog RUM events
  if (globalThis.DD_RUM) {
    globalThis.DD_RUM.setGlobalContextProperty('xinglet', {
      name: xingletName.replace('@xing-com/crate-', ''),
      pageName,
    });
  }

  const loginState = useLoginState();
  const host = useHost();
  const loginAppUrl = useLoginAppUrl();

  const layout = loginState.isLoggedIn ? layoutLoggedIn : layoutLoggedOut;

  // available for logged in users only, redirect to login page
  if (loginState.isLoggedOut && !visibleTo.loggedOut) {
    return host.redirectToLogin();
  }
  if (loginState.isSoftLoggedIn && !visibleTo.softLoggedIn) {
    return host.redirectToLogin();
  }

  // path does not go to any xinglet (logged in + logged out)
  if (!visibleTo.loggedIn && !visibleTo.loggedOut) {
    return <NoAccessPage />;
  }
  // available for logged out users only
  if (loginState.isLoggedIn && !visibleTo.loggedIn) {
    return <NoAccessPage />;
  }

  if (host.isServer && loginState.isLoggedOut && data.cacheMaxAge) {
    host.setHeader(
      'cache-control',
      `max-age=0, s-maxage=${data.cacheMaxAge}, must-revalidate`
    );
  }

  const isBrowserEnvironment = typeof window !== 'undefined';
  const isSafari =
    isBrowserEnvironment &&
    /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  const isLoginHostname = loginAppUrl.includes(host.getHostname());
  const isSafariAndLoginHostname = isSafari && isLoginHostname;

  return (
    <>
      <CookieConsentUsercentrics
        disableBanner={disableCookieConsentBanner || isSafariAndLoginHostname}
      />
      <ActiveItemContext.Provider value={activeItem}>
        <LayoutSwitcher
          {...{ layout, loginState, loginDestination, loginTracking }}
        >
          <RouteXinglet
            name={xingletName}
            pageName={pageName}
            trackingChannel={trackingChannel}
          />
        </LayoutSwitcher>
      </ActiveItemContext.Provider>
    </>
  );
};
