import type { Configuration, Language, Translate } from '../types';
import { headerLoggedIn } from './header-logged-in';
import { headerLoggedOut } from './header-logged-out';
import { moreMenuDropdown } from './more-menu-dropdown';
import { settingsDropdown } from './settings-dropdown';
import { verticalNavigation } from './vertical-navigation';

export const v1: Configuration = (t: Translate, lang: Language) => ({
  navigation: {
    verticalNavigation: verticalNavigation(t, lang),
    headerLoggedIn: headerLoggedIn(t, lang),
    headerLoggedOut: headerLoggedOut(t, lang),
    settingsDropdown: settingsDropdown(t, lang),
    moreMenuDropdown: moreMenuDropdown(t, lang),
  },
  experiments: [],
});
