// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { Button } from '@xing-com/button';
import { mediaTopBarHidden, mediaTopBarVisible } from '@xing-com/layout-tokens';
import { addParams } from '@xing-com/xing-tracking';

export type LoginButtonProps = {
  loginLink: string | undefined;
};

const pickMediaQuery = ({ size }: { size: string }): string => {
  return size === 'small' ? mediaTopBarHidden : mediaTopBarVisible;
};

export const StyledButtonSmallWhenTopBar = styled(Button)`
  @media ${pickMediaQuery} {
    display: none;
  }
`;

const ButtonSmallWhenTopBar: React.FC<
  Omit<React.ComponentProps<typeof Button>, 'size'>
> = (props) => {
  return (
    <>
      <StyledButtonSmallWhenTopBar {...props} size="small" />
      <StyledButtonSmallWhenTopBar {...props} size="medium" />
    </>
  );
};

export const LoginButton: React.FC<LoginButtonProps> = ({ loginLink }) => {
  const [loginButtonTo, setLoginButtonTo] = useState<string | undefined>(
    undefined
  );
  const { formatMessage } = useIntl();

  const trackingUrl =
    loginLink &&
    addParams(loginLink, {
      sc_o: 'navigation_sign_up_button',
    });

  useEffect(() => {
    setLoginButtonTo(trackingUrl);
  }, [trackingUrl]);

  return (
    <ButtonSmallWhenTopBar
      data-testid="styled-Login-Button"
      to={loginButtonTo}
      href={loginLink}
      variant="primary"
      // @ts-expect-error FIXME: SC6
      size="large"
    >
      {formatMessage({
        id: 'MAINNAV_LOGIN',
      })}
    </ButtonSmallWhenTopBar>
  );
};
